import * as React from "react";
import {Route, Routes} from "react-router-dom";
import {lazy} from "react";
const KidMealMain = lazy(() => import('../v2/views/payment/kid_meal/kid_meal_main'));
const DeliverySelectCoupon = lazy(() => import("../v2/views/payment/refridge/SelectCouponMain"));
const WdgClubMain = lazy(() => import("../v2/views/payment/wdg_club/wdg_club_main"))
const WdgClubSelectCoupon = lazy(() => import("../v2/views/payment/wdg_club/SelectCoupon"));

const PaymentRoute = () => {
  return (
    <Routes>
      <Route path="kidMeal/:orderId" element={<KidMealMain/>}/>
      <Route path="kidMeal" element={<KidMealMain/>}/>
      <Route path="refridge/selectCoupon" element={<DeliverySelectCoupon/>} />
      <Route path="wdgClub" element={<WdgClubMain/>}/>
      <Route path="wdgClub/:paidMembershipNumber" element={<WdgClubMain/>}/>
      <Route path="wdgClub/coupons/:paidMembershipNumber" element={<WdgClubSelectCoupon/>}/>
    </Routes>
  );
}

export default PaymentRoute;
