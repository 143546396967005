import * as React from "react";
import {lazy} from "react";
import {Route, Routes} from "react-router-dom";

const VerificationMain = lazy(() => import("../views/verification/verification_main"));
const VerificationTerms = lazy(() => import("../views/verification/verification_terms"));

const verificationRoute = () => {
    return (
        <Routes>
            <Route path="main" element={<VerificationMain/>}/>
            <Route path="terms/:telecom/:term" element={<VerificationTerms/>}/>
        </Routes>
    );
}

export default verificationRoute;
