import * as React from "react";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

const LinkView = () => {
  let {search} = useLocation();
  if (search.startsWith("?view%3D")) {
    // query가 url encoding된 케이스
    // https://m.woodongs.com/link?view%3Dclub%26tab1%3D{tab1}
    search = decodeURIComponent(search);
  }

  const viewParam = new URLSearchParams(search).get('view');
  const urlParam = new URLSearchParams(search).get('url');
  let adGroup;
  if (viewParam === 'web' && urlParam) {
    const url = new URL(urlParam);
    adGroup = encodeURIComponent(url.host + url.pathname)
  }

  const campaign = viewParam;
  const deeplink_url = encodeURIComponent(`woodongs://link${search}`);

  useEffect(() => {
    if (viewParam != null) {
      // https://help.airbridge.io/ko/guides/tracking-link-structure-and-parameters#%ED%8A%B8%EB%9E%98%ED%82%B9-%EB%A7%81%ED%81%AC-%EA%B5%AC%EC%A1%B0-%EC%95%8C%EC%95%84%EB%B3%B4%EA%B8%B0
      let redirectUrl = `https://abr.ge/@ourgs/ourgsapp?campaign=${campaign}&routing_short_id=sw9g7f&ad_type=click&deeplink_url=${deeplink_url}`;
      if (adGroup) {
        redirectUrl += `&ad_group=${adGroup}`;
      }
      window.location.replace(redirectUrl);
    }
  }, [search, viewParam, adGroup]);

  return <div/>;
};

export default LinkView;
