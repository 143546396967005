import * as React from "react";
import {lazy} from "react";
import {Route, Routes} from "react-router-dom";

const GsTownClubGuide = lazy(() => import("../views/membership/gstown_club_guide"));
const ThePopMemberShip = lazy(() => import("../views/membership/thepop_membership"));
const Wine25MemberShip = lazy(() => import("../views/membership/wine25_membership"));
const Wine25MemberShipRegistered = lazy(() => import("../views/membership/wine25_membership_registered"));
const Wine25MemberShipRegistered20230101 = lazy(() => import("../views/membership/wine25_membership_registered_v20230101"));
const Wine25MemberShipUnregistered = lazy(() => import("../views/membership/wine25_membership_unregistered"));
const Wine25MemberShipUnregistered20230101 = lazy(() => import("../views/membership/wine25_membership_unregistered_v20230101"));
const Wine25LevelInfo = lazy(() => import("../views/membership/Wine25LevelInfo"));
const UnintegratedRoyaltyMembership = lazy(() => import("../views/membership/royalty_membership/unintegrated_royalty_membership"));
const UnintegratedRoyaltyMembership20230921 = lazy(() => import("../views/membership/royalty_membership/unintegrated_royalty_membership_v20230921"));

const MemberShipRoute = () => {
    return (
        <Routes>
            <Route path="gstown" element={<GsTownClubGuide/>}/>
            <Route path="thepop" element={<ThePopMemberShip/>}/>
            <Route path="wine25" element={<Wine25MemberShip/>}/>
            <Route path="wine25-registered" element={<Wine25MemberShipRegistered/>}/>
            <Route path="wine25-registered-20230101" element={<Wine25MemberShipRegistered20230101/>}/>
            <Route path="wine25-unregistered" element={<Wine25MemberShipUnregistered/>}/>
            <Route path="wine25-unregistered-20230101" element={<Wine25MemberShipUnregistered20230101/>}/>
            <Route path="wine25-level-info" element={<Wine25LevelInfo/>} />
            <Route path="royalty-membership-unintegrated" element={<UnintegratedRoyaltyMembership/>}/>
            <Route path="royalty-membership-unintegrated-20230921" element={<UnintegratedRoyaltyMembership20230921/>}/>
        </Routes>
    );
}

export default MemberShipRoute;
