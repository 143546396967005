import React, {useMemo, useState} from "react";
import * as NativeUtil from "../utils/native_util";

const AuthRoute = ({element}) => {
  const [loaded, setLoaded] = useState(false);

  useMemo(async () => {
    if (!(await NativeUtil.isLogin())) {
      await NativeUtil.postMessage('Login');
      if (!(await NativeUtil.isLogin())) {
        NativeUtil.finish();
        return;
      }
    }

    setLoaded(true);
  }, []);

  if (!loaded) {
    return null;
  }

  return element;
}

export default AuthRoute;
