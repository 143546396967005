import * as promiseFinally from "promise.prototype.finally";
import * as CookieUtil from "./cookie_util";
import {LSKEY_MAIN_EVENT_ATTEND_INFO} from "v2/views/event/gs/attendance/const";
import moment from 'moment-timezone';

// IOS 11 에서 finally not found 에러 발생
promiseFinally.shim();

// [iOS] Disable selection/Copy of UIWebView
document.documentElement.style.webkitUserSelect = 'none';
// [iOS] Disable callout
document.documentElement.style.webkitTouchCallout = 'none';

export const isFlutter = () => !!window.flutter_inappwebview;

export const isAndroid = () => navigator.userAgent.match(/woodongs_android_/) != null;

export const isiOS = () => navigator.userAgent.match(/woodongs_ios_/) != null;

export function postMessage(name, params, useConsoleLog = true) {
  if (useConsoleLog) {
    console.log('[Native]', name, JSON.stringify(params));
  }
  if (isFlutter()) {
    if (window.flutter_inappwebview.callHandler) {
      return window.flutter_inappwebview.callHandler(name, params);
    } else {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          postMessage(name, params, useConsoleLog).then(resolve).catch(reject);
        }, 100);
      });
    }
  }
}

export function goBack() {
  if (isFlutter()) {
    postMessage('goBack');
  } else {
    window.history.back();
  }
}

export function goHome() {
  if (isFlutter()) {
    postMessage('goHome');
  } else {
    window.history.back();
  }
}

export function finish(data) {
  if (isFlutter()) {
    postMessage('finish', data);
  } else {
    window.close();
  }
}

/**
 * 외부 브라우저를 실행하여 페이지를 표시한다.
 * @param url
 */
export function launchExternal(url) {
  if (isFlutter()) {
    postMessage('launchExternal', url);
  } else {
    window.open(url, '_blank');
  }
}

/**
 * 외부 브라우저 앱을 실행하여 페이지를 표시한다.
 * @param url
 */
export function launchExternalApplication(url) {
  if (isFlutter()) {
    postMessage('launchExternalApplication', url);
  } else {
    window.open(url, '_blank');
  }
}

export function launchInternal(url, title = null) {
  return launchInternalEx({url, title});
}

/**
 *
 * @param url
 * @param title
 * @param actions [Home, CatBot]
 * @returns {*}
 */
export function launchInternalEx(params) {
  let {url, ...rest} = params;
  if (url?.startsWith('/')) {
    url = window.location.origin + url;
  }
  if (isFlutter()) {
    return postMessage('launchInternal', {url, ...rest});
  } else {
    window.open(url, '_blank');
  }
}

export function permissionRequest() {
  if (isFlutter()) {
    return postMessage('permissionRequest');
  } else {
    return true;
  }
}

export function isLogin() {
  if (isFlutter()) {
    return postMessage('isLogin');
  } else {
    return true;
  }
}

export function getAccessToken() {
  if (isFlutter()) {
    return postMessage('getAccessToken');
  } else {
    return CookieUtil.getAccessToken();
  }
}

export async function setLocalData(key, data) {

  if (key === null || typeof (key) !== "string") {
    console.log("key 정보가 있어야 합니다");
    return;
  }

  //등록
  const params = {
    key,
    data: JSON.stringify(data),
  };
  if (isFlutter()) {
    return postMessage('setLocalData', params);
  }
}

export async function getLocalData(key) {
  if (key === null || typeof (key) !== "string") {
    console.log("key 정보가 있어야 합니다");
    return;
  }

  const params = {
    key
  }

  if (isFlutter()) {
    return postMessage('getLocalData', params);
  }
}

//출석 이벤트 알림 여부 앱 로컬스토리지에 저장
export async function setAttndLocalData(data) {
  const userInfo = await getCustomerInfo();
  const key = LSKEY_MAIN_EVENT_ATTEND_INFO + "_" + userInfo.customerInfo.mobilCustomerNumber;

  return setLocalData(key, data);
}

//출석 이벤트
export async function getAttndLocalData() {
  const userInfo = await getCustomerInfo();
  const key = LSKEY_MAIN_EVENT_ATTEND_INFO + "_" + userInfo.customerInfo.mobilCustomerNumber;

  return getLocalData(key);
}


export function getDeviceId() {
  if (isFlutter()) {
    return postMessage('getDeviceId');
  } else {
    return CookieUtil.getDeviceId();
  }
}

export function openContactList() {
  if (isFlutter()) {
    return postMessage('openContactList');
  } else {
    return {phone: '010-1234-5678'};
  }
}

export function openStoreSearch() {
  if (isFlutter()) {
    return postMessage('openStoreSearch');
  } else {
    alert('매장찾기');
  }
}

export function openStoreSelect(serviceCode) {
  if (isFlutter()) {
    return postMessage('openStoreSelect', {serviceCode});
  } else {
    alert('매장선택');
  }
}

export function openChatBot() {
  if (isFlutter()) {
    postMessage('openChatBot');
  } else {
    alert('챗봇');
  }
}

export function toast(message) {
  if (isFlutter()) {
    postMessage('toast', message);
  } else {
    alert(message);
  }
}

export function openAddressSearch() {
  if (isFlutter()) {
    return postMessage('openAddressSearch');
  } else {
    alert('주소검색');
  }
}

export function openConfirmDialog(message, left, right, subMessage, subImageUrl, subImageWidth, subImageHeight, textAlign) {
  if (isFlutter()) {
    return postMessage('openConfirmDialog', {
      message,
      left,
      right,
      subMessage,
      subImageUrl,
      subImageWidth,
      subImageHeight,
      textAlign
    });
  } else {
    alert(message);
  }
}

export function openAlertDialog(message, textButton, subMessage, subImageUrl, subImageWidth, subImageHeight) {
  if (isFlutter()) {
    return postMessage('openAlertDialog', {
      message,
      textButton,
      subMessage,
      subImageUrl,
      subImageWidth,
      subImageHeight
    });
  } else {
    alert(message);
  }
}

export function getBuildNumber() {
  if (isFlutter()) {
    return postMessage('getBuildNumber');
  } else {
    return '428';
  }
}

export function getPackageName() {
  if (isFlutter()) {
    return postMessage('getPackageName');
  } else {
    return 'unknown';
  }
}

export function getCustomerInfo() {
  if (isFlutter()) {
    return postMessage('getCustomerInfo');
  } else {
    return {};
  }
}

export function getNetfunnelAction(action) {
  if (isFlutter()) {
    return postMessage('getNetfunnelAction', action);
  } else {
    alert('유량제어 action');
  }
}

export function getNetfunnelComplete() {
  if (isFlutter()) {
    return postMessage('getNetfunnelComplete');
  } else {
    alert('유량제어 complete');
  }
}

export function openAppStore() {
  if (isAndroid()) {
    launchExternal('market://details?id=com.gsr.gs25');
  } else {
    launchExternal('https://apps.apple.com/app/id426644449');
  }
}

export function openSelectBottomSheet(title, items, initialItem) {
  if (isFlutter()) {
    return postMessage('openSelectBottomSheet', {title, items, initialItem});
  } else {
    alert('선택팝업');
  }
}

export function openItemSelectBottomSheet(title, items, initialItem) {
  if (isFlutter()) {
    return postMessage('openItemSelectBottomSheet', {title, items, initialItem});
  } else {
    alert('선택팝업');
  }
}

export function apiRequestLog(method, apiUrl, headers, queryParameters, data) {
  try {
    if (isFlutter()) {
      postMessage('apiRequestLog', {method, apiUrl, headers, queryParameters, data}, false);
    }
  } catch (e) {
  }
}

export function apiResponseLog(method, apiUrl, statusCode, data) {
  try {
    if (isFlutter()) {
      postMessage('apiResponseLog', {method, apiUrl, statusCode, data}, false);
    }
  } catch (e) {
  }
}

export function airbridgeSendEvent({category, action, label, value, customAttributes, semanticAttributes}) {
  try {
    if (isFlutter()) {
      postMessage('airbridgeSendEvent', {category, action, label, value, customAttributes, semanticAttributes});
    }
  } catch (e) {
  }
}

export function amplitudeSendEvent({message, amplitudeEventProperties, amplitudeOutOfSession}) {
  try {
    if (isFlutter()) {
      postMessage('amplitudeSendEvent', {message, amplitudeEventProperties, amplitudeOutOfSession});
    }
  } catch (e) {
  }
}

export async function kstDate() {
  let now = moment();
  if (isFlutter()) {
    const unixEpoch = await postMessage('kstDate');
    if (unixEpoch) {
      now = moment(unixEpoch);
    }
  }
  return now.tz("Asia/Seoul");
}

export function gsPayShare({action, registrationKey}) {
  try {
    if (isFlutter()) {
      postMessage('gsPayShare', {action, registrationKey});
    }
  } catch (e) {
  }
}

export function setFixedViewPort() {
  const minWidth = 375;
  const width = getWindowInnerWidth();
  const viewport = document.getElementById('viewport');
  if (viewport) {
    //floor를 사용해서 소수점 뒷자리 내림
    const ratio = Math.floor((width / minWidth) * 1000) / 1000;
    viewport.setAttribute(
      'content',
      `width=${minWidth},initial-scale=${ratio},minimum-scale=${ratio},maximum-scale=${ratio},user-scalable=no,viewport-fit=cover`,
    );
  }

  window.addEventListener('resize', setFixedViewPort, {once: true});
}

export function getWindowInnerWidth() {
  if (window.visualViewport && window.visualViewport.width > 0) {
    return Math.round(window.visualViewport.width * window.visualViewport.scale);
  } else {
    return window.innerWidth;
  }
}

export function getWindowInnerHeight() {
  if (window.visualViewport && window.visualViewport.height > 0) {
    return window.visualViewport.height;
  } else {
    return window.innerHeight;
  }
}

// safe area 를 제외한 실제높이만 가져온다.
export function getHeight() {
  return getWindowInnerHeight() - getSafeAreaTop() - getSafeAreaBottom();
}

export function addWindowEventListener(type, listener) {
  if (window.visualViewport) {
    window.visualViewport.addEventListener(type, listener);
  } else {
    window.addEventListener(type, listener);
  }
}

export function removeWindowEventListener(type, listener) {
  if (window.visualViewport) {
    window.visualViewport.removeEventListener(type, listener);
  } else {
    window.removeEventListener(type, listener);
  }
}

export function getSafeAreaTop() {
  return parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sat"), 10);
}

export function getSafeAreaBottom() {
  return parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab"), 10);
}

export function getKidMealCard() {
  if (isFlutter()) {
    return postMessage('getLocalData', {key: "GET_KIDMEAL_CARD"});
  } else {
    alert('우리동네GS 앱 전용 입니다.')
  }
}

export function registerKidMealCard(params) {
  if (isFlutter()) {
    return postMessage('openRegisterKidMealCard', params);
  } else {
    alert('우리동네GS 앱 전용 입니다.')
  }
}

export function goToPaymentomplete(eventName, params) {
  if (isFlutter()) {
    return postMessage(eventName, params);
  } else {
    alert('우리동네GS 앱 전용 입니다.')
  }
}

export function getPaymentParameter() {
  if (isFlutter()) {
    return postMessage('paymentParameter');
  } else {
    alert('우리동네GS 앱 전용 입니다.')
  }
}

export function openISPApp(ispScheme, marketPkg)  {
  if (isFlutter()) {
    return postMessage('pluginOpenISP', {
      intent: ispScheme,
      marketPkg
    });
  } else {
    alert('우리동네GS 앱 전용 입니다.')
  }
}

export function waitForResume() {
  if (isFlutter()) {
    return postMessage('waitForResume');
  } else {
    alert('우리동네GS 앱 전용 입니다.')
  }
}