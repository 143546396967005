import * as React from "react";
import {lazy} from "react";
import {Route, Routes} from "react-router-dom";

const GsPayShareIntro = lazy(() => import("../views/gspay_share/gspay_share_intro"));
const GsPayShareMain = lazy(() => import("../views/gspay_share/gspay_share_main"));

const GsPayShareRoute = () => {
  return (
    <Routes>
      <Route path="intro" element={<GsPayShareIntro/>}/>
      <Route path="main" element={<GsPayShareMain/>}/>
    </Routes>
  );
}

export default GsPayShareRoute;
