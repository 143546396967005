import Loader from "react-loader-spinner";
import * as React from 'react';


const Spinner = () => {
  return <Loader
    type="TailSpin"
    color="#00c8af"
    height={40}
    width={40}
    className="render-loading"
  />;
};

export default Spinner;
