import * as React from 'react';
import {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import MemberShipRoute from './routes/membership_route';
import HelpRoute from './routes/help_route';
import GuaranteeRoute from './routes/guarantee_route';
import LinkView from "./views/link/link_view";
import EventRoute from "./routes/event_route";
import LgUPlusSubscriptionRoute from "./routes/lguplus_subscription_route";
import VerificationRoute from './routes/verification_route';
import PaymentRoute from "./routes/payment_route";
import GsPayShareRoute from "./routes/gspay_share_route";
import GiftCardRoute from "./routes/gift_card_route";

const Redirect = ({url}) => {
  useEffect(() => {
    window.location.href = url;
  }, []);

  return null;
};

function App() {
  useEffect(() => {
    console.log(`env=${process.env.REACT_APP_ENV}`);
  }, []);
  return (
    <div>
      <Routes>
        <Route path="home" element={<Redirect url='https://gs25.gsretail.com/gscvs/ko/store-services/woodongs'/>}/>
        <Route path="link" element={<LinkView/>}/>
        <Route path="membership/*" element={<MemberShipRoute/>}/>
        <Route path="help/*" element={<HelpRoute/>}/>
        <Route path="guarantee/*" element={<GuaranteeRoute/>}/>
        <Route path="event/*" element={<EventRoute/>}/>
        <Route path="lguplus_subscription/*" element={<LgUPlusSubscriptionRoute/>}/>
        <Route path="verification/*" element={<VerificationRoute/>}/>
        <Route path="payment/*" element={<PaymentRoute/>}/>
        <Route path="gspay_share/*" element={<GsPayShareRoute/>}/>
        <Route path="gift_card/*" element={<GiftCardRoute/>}/>
      </Routes>
    </div>
  );
}

export default App;
