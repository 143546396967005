import * as React from "react";
import {lazy} from "react";
import {Route, Routes} from "react-router-dom";

const GiftCardIntro = lazy(() => import("../views/gift_card/gift_card_intro"));

const GiftCardRoute = () => {
  return (
    <Routes>
      <Route path="intro" element={<GiftCardIntro/>}/>
    </Routes>
  );
}

export default GiftCardRoute;
