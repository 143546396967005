import * as React from "react";
import {lazy} from "react";
import {Route, Routes} from "react-router-dom";

const SafetyService = lazy(() => import("../views/guarantee/safety_service"));

const GuaranteeRoute = () => {
  return (
    <Routes>
      <Route path="safety_service" element={<SafetyService/>}/>
    </Routes>
  );
}

export default GuaranteeRoute;
