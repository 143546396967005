import {datadogRum} from '@datadog/browser-rum';
import {datadogLogs} from '@datadog/browser-logs';
import * as CookieUtil from './cookie_util';

export function init() {
  datadogRum.init({
    applicationId: '615c2c99-4a64-43e3-a566-4ae9d50d7976',
    clientToken: 'pub5c1ec082b4cb8eca2ccce1e141b3ebe0',
    site: 'datadoghq.com',
    service: 'woodong_mo_ui_react',
    env: process.env.REACT_APP_DATADOG_ENV,
    version: process.env.REACT_APP_VERSION,
    sampleRate: Number(process.env.REACT_APP_DATADOG_RUM_SAMPLE_RATE),
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [new RegExp(`https:\/\/.*\\.${process.env.REACT_APP_DATADOG_ALLOWED_TRACING_ORIGINS}\\.com`)]
  });

  datadogLogs.init({
    clientToken: 'pub5c1ec082b4cb8eca2ccce1e141b3ebe0',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: Number(process.env.REACT_APP_DATADOG_LOG_SAMPLE_RATE)
  });

  const userInfo = CookieUtil.getUserInfo();
  if (userInfo != null) {
    datadogRum.setUser(userInfo);
  }
}
