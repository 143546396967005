import * as React from "react";
import {lazy} from "react";
import {Route, Routes} from "react-router-dom";

const RegisterLifeCock = lazy(() => import("../views/lguplus_subscription/register_life_cock"));
const RegisterUDock = lazy(() => import("../views/lguplus_subscription/register_udock"));
const RegisterComplete = lazy(() => import("../views/lguplus_subscription/register_complete"));
const AlreadyRegistered = lazy(() => import("../views/lguplus_subscription/already_registered"));

const LgUPlusSubscriptionRoute = () => {
    return (
        <Routes>
            <Route path="register-life-cock" element={<RegisterLifeCock/>}/>
            <Route path="register-life-udock" element={<RegisterUDock/>}/>
            <Route path="register-udock" element={<RegisterUDock/>}/>
            <Route path="register-complete" element={<RegisterComplete/>}/>
            <Route path="already-registered" element={<AlreadyRegistered/>}/>
        </Routes>
    );
}

export default LgUPlusSubscriptionRoute;
