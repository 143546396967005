/**
 * 운영환경 여부
 * @returns {boolean}
 */
export function isProduction() {
    return process.env.REACT_APP_ENV === "production";
}

/**
 * 개발환경 여부
 * @returns {boolean}
 */
export function isDevelopment() {
    return process.env.REACT_APP_ENV === "development";
}